// Display footer date
function getParisTime() {
  const element = document.querySelector('.js-time');
  const options = {
      timeZone: 'Europe/Paris',
      hour: 'numeric',
      minute: 'numeric',
    },
    formatter = new Intl.DateTimeFormat([], options);

  element.textContent = formatter.format(new Date());
}

const time = 5000;
setInterval(() => getParisTime(), time);

// header
function header() {
  const location = window.location.pathname
    .replace('/', '')
    .replace('.html', '')
    .replace('index', '');

  const header = document.querySelector('.header');
  const links = header.querySelectorAll('a');

  for (let index = 0; index < links.length; index++) {
    const link = links[index];
    const namePage = link.innerText.toLowerCase();

    if (location !== namePage && location !== '') {
      link.classList.add('is-active');
    }
  }
}
header();

// display mobile header
function mobileHeader() {
  const header = document.querySelector('.header');
  const headerBtn = header.querySelector('.header__menu-mobile');

  headerBtn.addEventListener('click', () => {
    header.classList.toggle('is-open');
  });
}
mobileHeader();

// Thesis animation
function isVisible(el) {
  const { top, bottom } = el.getBoundingClientRect();
  const vHeight =
    window.innerHeight / 1.5 || document.documentElement.clientHeight / 1.5;

  return (
    (top > 0 || bottom > 0) && top < vHeight - 100 && bottom > vHeight - 200
  );
}

function animationList() {
  const list = document.querySelector('.list-num');

  if (list) {
    const elements = list.querySelectorAll('li');
    elements[0].classList.add('is-active');

    document.addEventListener('scroll', () => {
      for (let index = 0; index < elements.length; index++) {
        const element = elements[index];
        if (isVisible(element)) {
          element.classList.add('is-active');
        } else {
          element.classList.remove('is-active');
        }
      }
    });
  }
}
animationList();

function togglePhotos() {
  const photoBtn = document.querySelector('.section-about__button');

  if (photoBtn) {
    const photoWrapper = document.querySelector('.section-about__photos');
    let photoHeight = 0;

    function fadeOutPhotos() {
      photoWrapper.classList.add('is-hidden');
      photoBtn.style.zIndex = '1';
    }

    photoBtn.addEventListener('click', (e) => {
      e.stopPropagation();
      photoWrapper.classList.remove('is-hidden');
      photoBtn.style.zIndex = '0';
      photoHeight = photoWrapper.querySelector('img').offsetHeight;

      window.addEventListener('scroll', () => {
        const wrapperRect = photoWrapper.getBoundingClientRect();
        if (wrapperRect.bottom < photoHeight / 1.5 || wrapperRect.top > photoHeight / 1.5) {
          fadeOutPhotos();
        }
      });
    });

    document.body.addEventListener('click', (e) => {
      fadeOutPhotos();
    });

    photoWrapper.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  }
}
togglePhotos();
